<template>
  <div class="section">
    <div v-if="reservation" class="container">
      <div class="columns is-large-gap">
        <div class="column is-6">
          <div class="columns columns-table is-gapless is-mobile is-size-7 m-b-0">
            <div class="column">
              <div class="th">
                {{ $t('Basic Information') }}
              </div>
            </div>
            <div class="column has-text-right is-narrow">
              <div class="th">
                <Modal>
                  <template #default="{ hide }">
                    <EditBasicInfo
                      :value="reservation"
                      @close="
                        () => {
                          hide();
                          asyncData();
                        }
                      "
                      @cancel="hide"
                    />
                  </template>
                  <template #handler="{ show }">
                    <a class="has-text-white" @click.prevent="show">
                      <IconBase width="13" height="13" icon-name="edit">
                        <IconEdit />
                      </IconBase>
                      {{ $t('Edit') }}</a>
                  </template>
                </Modal>
              </div>
            </div>
          </div>
          <div class="columns columns-table is-gapless is-multiline is-size-7 m-b-0">
            <div class="column is-6">
              <div class="td">
                <div class="has-text-primary has-text-weight-bold">
                  {{ $t('Travel Agency Booking Number') }}
                </div>
                <div>{{ basicInfo.agencyBookingNumber }}</div>
              </div>
            </div>
            <div class="column is-6">
              <div class="td">
                <div class="has-text-primary has-text-weight-bold">
                  {{ $t('Check Out Date') }}
                </div>
                <div>{{ reservation.checkout }}</div>
              </div>
            </div>
            <div class="column is-6">
              <div class="td">
                <div class="has-text-primary has-text-weight-bold">
                  {{ $t('Travel Agency Booking Date') }}
                </div>
                <div>{{ basicInfo.agencyBookingDate }}</div>
              </div>
            </div>
            <div class="column is-6">
              <div class="td">
                <div class="has-text-primary has-text-weight-bold">
                  {{ $t('Nights') }}
                </div>
                <div>{{ basicInfo.nights }}</div>
              </div>
            </div>
            <div class="column is-6">
              <div class="td">
                <div class="has-text-primary has-text-weight-bold">
                  {{ $t('Travel Agency Booking Time') }}
                </div>
                <div>{{ basicInfo.agencyBookingTime }}</div>
              </div>
            </div>
            <div class="column is-6">
              <div class="td">
                <div class="has-text-primary has-text-weight-bold">
                  {{ $t('Package Plan Name') }}
                </div>
                <div>{{ basicInfo.packagePlanName }}</div>
              </div>
            </div>
            <div class="column is-6">
              <div class="td">
                <div class="has-text-primary has-text-weight-bold">
                  {{ $t('Guest Or Group Name Single Byte') }}
                </div>
                <div>{{ basicInfo.guestNameSingleByte }}</div>
              </div>
            </div>
            <div class="column is-6">
              <div class="td">
                <div class="has-text-primary has-text-weight-bold">
                  {{ $t('Package Plan Code') }}
                </div>
                <div>{{ basicInfo.packagePlanCode }}</div>
              </div>
            </div>
            <div class="column is-6">
              <div class="td">
                <div class="has-text-primary has-text-weight-bold">
                  {{ $t('Guest Or Group Name Double Byte') }}
                </div>
                <div>{{ basicInfo.guestNameDoubleByte }}</div>
              </div>
            </div>
            <div class="column is-6">
              <div class="td">
                <div class="has-text-primary has-text-weight-bold">
                  {{ $t('Other Service Information') }}
                </div>
                <div>{{ basicInfo.otherServiceInfo }}</div>
              </div>
            </div>
            <div class="column is-6">
              <div class="td">
                <div class="has-text-primary has-text-weight-bold">
                  {{ $t('Guest Or Group Name Kanji Name') }}
                </div>
                <div>{{ basicInfo.guestNameKanji }}</div>
              </div>
            </div>
            <div class="column is-6">
              <div class="td">
                <div class="has-text-primary has-text-weight-bold">
                  {{ $t('Original Message') }}
                </div>
                <div>{{ basicInfo.originalMessage }}</div>
              </div>
            </div>
            <div class="column is-6">
              <div class="td">
                <div class="has-text-primary has-text-weight-bold">
                  {{ $t('Check In Date') }}
                </div>
                <div>{{ reservation.checkin }}</div>
              </div>
            </div>
            <div class="column is-6">
              <div class="td">
                <div class="has-text-primary has-text-weight-bold">
                  {{ $t('Specific Meal Condition') }}
                </div>
                <div>{{ basicInfo.specificMealCondition }}</div>
              </div>
            </div>
            <div class="column is-6">
              <div class="td">
                <div class="has-text-primary has-text-weight-bold">
                  {{ $t('Check In Time') }}
                </div>
                <div>{{ basicInfo.checkinTime }}</div>
              </div>
            </div>
            <div v-if="details.reservationId" class="column is-6">
              <div class="td">
                <div class="has-text-primary has-text-weight-bold">
                  {{ $t('Check In Status') }}
                </div>
                <div>{{ details.idVerifiyCompleted ? $t('Checked in') : $t('Not started') }}</div>
              </div>
            </div>
            <div v-if="details.reservationId" class="column is-6">
              <div class="td">
                <div class="has-text-primary has-text-weight-bold">
                  {{ $t('Check Out Status') }}
                </div>
                <div>{{ details.checkoutDate ? $t('Checked out') : $t('Not started') }}</div>
              </div>
            </div>
            <div v-if="details.reservationId" class="column is-6">
              <div class="td">
                <div class="has-text-primary has-text-weight-bold">
                  {{ $t('Temporary Key Code') }}
                </div>
                <div>{{ details.temporaryKeyCode }}</div>
              </div>
            </div>
            <div v-if="details.reservationId" class="column is-6">
              <div class="td">
                <div class="has-text-primary has-text-weight-bold">
                  {{ $t('Smartlock Key Code') }}
                </div>
                <div>{{ details.smartlockKeyCode }}</div>
              </div>
            </div>
            <div v-if="details.reservationId" class="column is-6">
              <div class="td">
                <div class="has-text-primary has-text-weight-bold">
                  {{ $t('Anshin Reservation ID') }}
                </div>
                <div>{{ details.reservationId }}</div>
              </div>
            </div>
          </div>
        </div>

        <div class="column is-6">
          <div class="columns columns-table is-gapless is-mobile is-size-7 m-b-0">
            <div class="column">
              <div class="th">
                {{ $t('Transaction Type') }}
              </div>
            </div>
            <div class="column has-text-right is-narrow">
              <div class="th">
                <Modal>
                  <template #default="{ hide }">
                    <EditTransactionType
                      :value="reservation"
                      @close="
                        () => {
                          hide();
                          asyncData();
                        }
                      "
                      @cancel="hide"
                    />
                  </template>
                  <template #handler="{ show }">
                    <a class="has-text-white" @click.prevent="show">
                      <IconBase width="13" height="13" icon-name="edit">
                        <IconEdit />
                      </IconBase>
                      {{ $t('Edit') }}</a>
                  </template>
                </Modal>
              </div>
            </div>
          </div>
          <div class="columns columns-table is-gapless is-multiline is-size-7 m-b-0">
            <div class="column is-6">
              <div class="td">
                <div class="has-text-primary has-text-weight-bold">
                  {{ $t('Data From') }}
                </div>
                <div>{{ transactionType.dataFrom }}</div>
              </div>
            </div>
            <div class="column is-6">
              <div class="td">
                <div class="has-text-primary has-text-weight-bold">
                  {{ $t('System Date') }}
                </div>
                <div>{{ transactionType.systemDate }}</div>
              </div>
            </div>
            <div class="column is-6">
              <div class="td">
                <div class="has-text-primary has-text-weight-bold">
                  {{ $t('Data Classification') }}
                </div>
                <div>{{ transactionType.dataClassification }}</div>
              </div>
            </div>
            <div class="column is-6">
              <div class="td">
                <div class="has-text-primary has-text-weight-bold">
                  {{ $t('System Time') }}
                </div>
                <div>{{ transactionType.systemTime }}</div>
              </div>
            </div>
          </div>
          <div class="columns columns-table is-gapless is-mobile is-size-7 m-b-0">
            <div class="column">
              <div class="th">
                {{ $t('Accommodation Information') }}
              </div>
            </div>
            <div class="column has-text-right is-narrow">
              <div class="th">
                <Modal>
                  <template #default="{ hide }">
                    <EditAccommodationInfo
                      :value="reservation"
                      @close="
                        () => {
                          hide();
                          asyncData();
                        }
                      "
                      @cancel="hide"
                    />
                  </template>
                  <template #handler="{ show }">
                    <a class="has-text-white" @click.prevent="show">
                      <IconBase width="13" height="13" icon-name="edit">
                        <IconEdit />
                      </IconBase>
                      {{ $t('Edit') }}</a>
                  </template>
                </Modal>
              </div>
            </div>
          </div>
          <div class="columns columns-table is-gapless is-multiline is-size-7 m-b-0">
            <div class="column is-6">
              <div class="td">
                <div class="has-text-primary has-text-weight-bold">
                  {{ $t('Accommodation Name') }}
                </div>
                <div>{{ accommodationInfo.name }}</div>
              </div>
            </div>
            <div class="column is-6">
              <div class="td">
                <div class="has-text-primary has-text-weight-bold">
                  {{ $t('Accommodation Code') }}
                </div>
                <div>{{ accommodationInfo.code }}</div>
              </div>
            </div>
          </div>

          <div class="columns columns-table is-gapless is-mobile is-size-7 m-b-0">
            <div class="column">
              <div class="th">
                {{ $t('Sales Office Information') }}
              </div>
            </div>
            <div class="column has-text-right is-narrow">
              <div class="th">
                <Modal>
                  <template #default="{ hide }">
                    <EditSalesOfficeInfo
                      :value="reservation"
                      @close="
                        () => {
                          hide();
                          asyncData();
                        }
                      "
                      @cancel="hide"
                    />
                  </template>
                  <template #handler="{ show }">
                    <a class="has-text-white" @click.prevent="show">
                      <IconBase width="13" height="13" icon-name="edit">
                        <IconEdit />
                      </IconBase>
                      {{ $t('Edit') }}</a>
                  </template>
                </Modal>
              </div>
            </div>
          </div>
          <div class="columns columns-table is-gapless is-mobile is-multiline is-size-7 m-b-0">
            <div class="column is-6">
              <div class="td">
                <div class="has-text-primary has-text-weight-bold">
                  {{ $t('Sales Office Company Code') }}
                </div>
                <div>{{ salesOfficeInfo.companyCode }}</div>
              </div>
            </div>
            <div class="column is-6">
              <div class="td">
                <div class="has-text-primary has-text-weight-bold">
                  {{ $t('Sales Office Code') }}
                </div>
                <div>{{ salesOfficeInfo.code }}</div>
              </div>
            </div>
            <div class="column is-6">
              <div class="td">
                <div class="has-text-primary has-text-weight-bold">
                  {{ $t('Sales Office Company Name') }}
                </div>
                <div>{{ salesOfficeInfo.companyName }}</div>
              </div>
            </div>
            <div class="column is-6">
              <div class="td">
                <div class="has-text-primary has-text-weight-bold">
                  {{ $t('Sales Office Person In Charge') }}
                </div>
                <div>{{ salesOfficeInfo.personInCharge }}</div>
              </div>
            </div>
            <div class="column is-6">
              <div class="td">
                <div class="has-text-primary has-text-weight-bold">
                  {{ $t('Sales Office Name') }}
                </div>
                <div>{{ salesOfficeInfo.name }}</div>
              </div>
            </div>
            <div class="column is-6">
              <div class="td">
                <div class="has-text-primary has-text-weight-bold">
                  {{ $t('Sales Office Phone Number') }}
                </div>
                <div>{{ salesOfficeInfo.phone }}</div>
              </div>
            </div>
          </div>

          <div class="columns columns-table is-gapless is-mobile is-size-7 m-b-0">
            <div class="column">
              <div class="th">
                {{ $t('Basic Rate Information') }}
              </div>
            </div>
            <div class="column has-text-right is-narrow">
              <div class="th">
                <Modal>
                  <template #default="{ hide }">
                    <EditBasicRateInfo
                      :value="reservation"
                      @close="
                        () => {
                          hide();
                          asyncData();
                        }
                      "
                      @cancel="hide"
                    />
                  </template>
                  <template #handler="{ show }">
                    <a class="has-text-white" @click.prevent="show">
                      <IconBase width="13" height="13" icon-name="edit">
                        <IconEdit />
                      </IconBase>
                      {{ $t('Edit') }}</a>
                  </template>
                </Modal>
              </div>
            </div>
          </div>
          <div class="columns columns-table is-gapless is-multiline is-size-7 m-b-0">
            <div class="column is-6">
              <div class="td">
                <div class="has-text-primary has-text-weight-bold">
                  {{ $t('Room Rate or Personal Rate') }}
                </div>
                <div>{{ basicRateInfo.rate }}</div>
              </div>
            </div>
            <div class="column is-6">
              <div class="td">
                <div class="has-text-primary has-text-weight-bold">
                  {{ $t('Payment') }}
                </div>
                <div>{{ basicRateInfo.payment }}</div>
              </div>
            </div>
            <div class="column is-6">
              <div class="td">
                <div class="has-text-primary has-text-weight-bold">
                  {{ $t('Tax Service Fee') }}
                </div>
                <div>{{ basicRateInfo.taxServiceFee }}</div>
              </div>
            </div>
            <div class="column is-6">
              <div class="td">
                <div class="has-text-primary has-text-weight-bold">
                  {{ $t('Total Accommodation Charge') }}
                </div>
                <div>{{ basicRateInfo.totalAccommodationCharge }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import Modal from '@/components/Modal';
import IconEdit from '@/components/icons/IconEdit';
import EditTransactionType from './components/booking-info/EditTransactionType';
import EditAccommodationInfo from './components/booking-info/EditAccommodationInfo';
import EditBasicRateInfo from './components/booking-info/EditBasicRateInfo';
import EditSalesOfficeInfo from './components/booking-info/EditSalesOfficeInfo';
import EditBasicInfo from './components/booking-info/EditBasicInfo';

export default {
  name: 'BookingInfo',
  components: {
    IconEdit,
    Modal,
    EditTransactionType,
    EditAccommodationInfo,
    EditBasicRateInfo,
    EditSalesOfficeInfo,
    EditBasicInfo,
  },
  computed: {
    ...mapState('reservations', ['reservation']),
    details() {
      return this.reservation.details || {};
    },
    basicInfo() {
      return this.details.basicInfo || {};
    },
    transactionType() {
      return this.details.transactionType || {};
    },
    accommodationInfo() {
      return this.details.accommodationInfo || {};
    },
    salesOfficeInfo() {
      return this.details.salesOfficeInfo || {};
    },
    basicRateInfo() {
      return this.details.basicRateInfo || {};
    },
  },
  methods: {
    asyncData() {
      this.$store.dispatch('reservations/get', { id: this.$route.params.id });
    },
  },
};
</script>

<i18n src="@/locales/components/reservations.json"></i18n>
