<template>
  <div class="section">
    <div v-if="reservation" class="container">
      <div class="columns is-large-gap">
        <!-- start of left section -->
        <div class="column is-6">
          <!-- start of basic information section -->
          <div class="columns columns-table is-gapless is-mobile is-size-7 m-b-0">
            <div class="column">
              <div class="th">
                {{ $t('Basic Information') }}
              </div>
            </div>
            <div class="column has-text-right is-narrow">
              <div class="th">
                <Modal>
                  <template #default="{ hide }">
                    <EditBasicInformation
                      :value="reservation"
                      @close="
                        () => {
                          hide();
                          asyncData();
                        }
                      "
                      @cancel="hide"
                    />
                  </template>
                  <template #handler="{ show }">
                    <a class="has-text-white" @click.prevent="show">
                      <IconBase width="13" height="13" icon-name="edit">
                        <IconEdit />
                      </IconBase>
                      {{ $t('Edit') }}</a>
                  </template>
                </Modal>
              </div>
            </div>
          </div>
          <div class="columns columns-table is-gapless is-multiline is-size-7 m-b-0">
            <div class="column is-6">
              <div class="td">
                <div class="has-text-primary has-text-weight-bold">
                  {{ $t('Travel Agency Booking Number') }}
                </div>
                <div>{{ basicInfo.agencyBookingNumber || '-' }}</div>
              </div>
            </div>
            <div class="column is-6">
              <div class="td">
                <div class="has-text-primary has-text-weight-bold">
                  {{ $t('Length of Stay') }}
                </div>
                <div>
                  {{
                    $filters.date(reservation.checkin, {
                      format: $variables.dateFormat,
                    })
                  }}
                  ~
                  {{
                    $filters.date(reservation.checkout, {
                      format: $variables.dateFormat,
                    })
                  }}
                </div>
              </div>
            </div>
            <div class="column is-6">
              <div class="td">
                <div class="has-text-primary has-text-weight-bold">
                  {{ $t('Property') }}
                </div>
                <div>{{ property.name || '-' }}</div>
              </div>
            </div>
            <div class="column is-6">
              <div class="td">
                <div class="has-text-primary has-text-weight-bold">
                  {{ $t('Room Type Name') }}
                </div>
                <div>{{ roomTypeName }}</div>
              </div>
            </div>
            <div class="column is-6">
              <div class="td">
                <div class="has-text-primary has-text-weight-bold">
                  {{ $t('Room Number') }}
                </div>
                <div>{{ roomNumber || '-' }}</div>
              </div>
            </div>
            <div class="column is-6">
              <div class="td">
                <div class="has-text-primary has-text-weight-bold">
                  {{ $t('Total Pax') }}
                </div>
                <div>{{ totalPax || '-' }}</div>
              </div>
            </div>
            <div class="column is-6">
              <div class="td">
                <div class="has-text-primary has-text-weight-bold">
                  {{ $t('Adult') }}
                </div>
                <div>{{ numberOfAdults || '-' }}</div>
              </div>
            </div>
            <div class="column is-6">
              <div class="td">
                <div class="has-text-primary has-text-weight-bold">
                  {{ $t('Child') }}
                </div>
                <div>{{ numberOfChildren || '-' }}</div>
              </div>
            </div>
            <div class="column is-6">
              <div class="td">
                <div class="has-text-primary has-text-weight-bold">
                  {{ $t('Infant') }}
                </div>
                <div>{{ numberOfInfrant || '-' }}</div>
              </div>
            </div>
            <div class="column is-6">
              <div class="td">
                <div class="has-text-primary has-text-weight-bold">
                  {{ $t('Travel Agency Name') }}
                </div>
                <div>{{ basicInfo.agencyName || '-' }}</div>
              </div>
            </div>
            <div class="column is-6">
              <div class="td">
                <div class="has-text-primary has-text-weight-bold">
                  {{ $t('Package Plan Name') }}
                </div>
                <div>{{ basicInfo.packagePlanName || '-' }}</div>
              </div>
            </div>
            <div class="column is-6">
              <div class="td">
                <div class="has-text-primary has-text-weight-bold">
                  {{ $t('OTA Name') }}
                </div>
                <div>{{ reservation.otaName || '-' }}</div>
              </div>
            </div>
            <div class="column is-6">
              <div class="td">
                <div class="has-text-primary has-text-weight-bold">
                  {{ $t('OTA Reservation Number') }}
                </div>
                <div>{{ reservation.otaReservationNumber || '-' }}</div>
              </div>
            </div>
            <div class="column is-6">
              <div class="td">
                <div class="has-text-primary has-text-weight-bold">
                  {{ $t('Anshin Reservation Number') }}
                </div>
                <div>{{ reservation.reservationNumber }}-{{ reservation.id }}</div>
              </div>
            </div>
          </div>
          <!-- the end of basic information section -->

          <!-- start of guest information section -->
          <div class="columns columns-table is-gapless is-mobile is-size-7 m-b-0">
            <div class="column">
              <div class="th">
                {{ $t('Guest Information') }}
              </div>
            </div>
            <div class="column has-text-right is-narrow">
              <div class="th">
                <Modal>
                  <template #default="{ hide }">
                    <EditGuestInformation
                      :value="reservation"
                      @close="
                        () => {
                          hide();
                          asyncData();
                        }
                      "
                      @cancel="hide"
                    />
                  </template>
                  <template #handler="{ show }">
                    <a class="has-text-white" @click.prevent="show">
                      <IconBase width="13" height="13" icon-name="edit">
                        <IconEdit />
                      </IconBase>
                      {{ $t('Edit') }}</a>
                  </template>
                </Modal>
              </div>
            </div>
          </div>
          <div class="columns columns-table is-gapless is-multiline is-size-7 m-b-0">
            <div class="column is-6">
              <div class="td">
                <div class="has-text-primary has-text-weight-bold">
                  {{ $t('Guest Name Single Byte') }}
                </div>
                <div>{{ guest.nameSingleByte || '-' }}</div>
              </div>
            </div>
            <div class="column is-6">
              <div class="td">
                <div class="has-text-primary has-text-weight-bold">
                  {{ $t('Guest Email') }}
                </div>
                <div>{{ guest.email || '-' }}</div>
              </div>
            </div>
            <div class="column is-6">
              <div class="td">
                <div class="has-text-primary has-text-weight-bold">
                  {{ $t('Guest Surname') }}
                </div>
                <div>{{ guest.surname || '-' }}</div>
              </div>
            </div>
            <div class="column is-6">
              <div class="td">
                <div class="has-text-primary has-text-weight-bold">
                  {{ $t('Guest Phone Number') }}
                </div>
                <div>{{ guest.phone || '-' }}</div>
              </div>
            </div>
            <div class="column is-6">
              <div class="td">
                <div class="has-text-primary has-text-weight-bold">
                  {{ $t('Guest Kanji Name') }}
                </div>
                <div>{{ guest.kanjiName || '-' }}</div>
              </div>
            </div>
          </div>
          <!-- the end of guest information section -->
        </div>
        <!-- the end of left section -->
        <!-- the start of right section -->
        <div class="column is-6">
          <!-- the start of permission -->
          <div class="columns columns-table is-gapless is-mobile is-size-7 m-b-0">
            <div class="column">
              <div class="th">
                {{ $t('Permission') }}
              </div>
            </div>
          </div>
          <div class="columns is-mobile mt-2">
            <div class="column">
              <div class="has-text-danger has-text-weight-bold">
                {{ $t('Delete reservation') }}
              </div>
              <div>{{ $t('del-property-desc') }}</div>
            </div>
            <div class="column is-narrow">
              <DelReservation
                :record="reservation"
                @delete="$router.push({ path: '/reservations' })"
              >
                <template #handler="{ show }">
                  <a-button type="danger" @click="show">
                    {{ $t('Delete') }}
                  </a-button>
                </template>
              </DelReservation>
            </div>
          </div>
          <!-- the end of permission -->

          <!-- the start of other service information -->
          <div class="columns columns-table is-gapless is-mobile is-size-7 m-b-0">
            <div class="column">
              <div class="th">
                {{ $t('Other Service Information') }}
              </div>
            </div>
            <div class="column has-text-right is-narrow">
              <div class="th">
                <Modal>
                  <template #default="{ hide }">
                    <EditOtherServiceInformation
                      :value="reservation"
                      @close="
                        () => {
                          hide();
                          asyncData();
                        }
                      "
                      @cancel="hide"
                    />
                  </template>
                  <template #handler="{ show }">
                    <a class="has-text-white" @click.prevent="show">
                      <IconBase width="13" height="13" icon-name="edit">
                        <IconEdit />
                      </IconBase>
                      {{ $t('Edit') }}
                    </a>
                  </template>
                </Modal>
              </div>
            </div>
          </div>
          <div class="column is-12">
            <div class="td">
              <div>
                {{ basicInfo.otherServiceInfo || '-' }}
              </div>
            </div>
          </div>
          <!-- the end of other service information -->

          <!-- the start of Charge -->
          <div class="columns columns-table is-gapless is-mobile is-size-7 m-b-0">
            <div class="column">
              <div class="th">
                {{ $t('Charge') }}
              </div>
            </div>
            <div class="column has-text-right is-narrow">
              <div class="th">
                <Modal>
                  <template #default="{ hide }">
                    <EditCharge
                      :value="reservation"
                      @close="
                        () => {
                          hide();
                          asyncData();
                        }
                      "
                      @cancel="hide"
                    />
                  </template>
                  <template #handler="{ show }">
                    <a class="has-text-white" @click.prevent="show">
                      <IconBase width="13" height="13" icon-name="edit">
                        <IconEdit />
                      </IconBase>
                      {{ $t('Edit') }}
                    </a>
                  </template>
                </Modal>
              </div>
            </div>
          </div>
          <div class="columns columns-table is-gapless is-multiline is-size-7 m-b-0">
            <div class="column is-6">
              <div class="td">
                <div class="has-text-primary has-text-weight-bold">
                  {{ $t('Total Accomodation Charge') }}
                </div>
                <div>{{ $filters.number(basicRateInfo.totalAccommodationCharge) || '-' }}</div>
              </div>
            </div>
            <div class="column is-6">
              <div class="td">
                <div class="has-text-primary has-text-weight-bold">
                  {{ $t('Latest guest email') }}
                </div>
                <div class="is-block">
                  {{ getSendTime(latestGuestEmail) }}
                  <span
                    v-if="latestGuestEmail"
                    class="email-status"
                    :class="guestEmailStatusClass(latestGuestEmail)"
                  >
                    {{ latestGuestEmail.status }}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div class="column is-12">
            <div class="td">
              <div class="has-text-primary has-text-weight-bold">
                {{ $t('Point Used') }}
              </div>
              <div>{{ member.totalAccommodationDecleasePoints || '-' }}</div>
            </div>
          </div>
          <div class="column is-12">
            <div class="td">
              <div class="has-text-primary has-text-weight-bold">
                {{ $t('Guest Payment') }}
              </div>
              <div>{{ basicRateInfo.payment || '-' }}</div>
            </div>
          </div>
          <div class="column is-12">
            <div class="td">
              <div class="has-text-primary has-text-weight-bold">
                {{ $t('Payment Method') }}
              </div>
              <div>{{ basicRateInfo.paymentMethod || '-' }}</div>
            </div>
          </div>
          <!-- the end of charge -->

          <!-- Housekeeping schedule -->
          <div class="columns columns-table is-gapless is-mobile is-size-7 m-b-0">
            <div class="column">
              <div class="th">
                {{ $t('Housekeeping Schedule') }}
              </div>
            </div>
          </div>
          <div class="column is-12">
            <table class="housekeeping-schedule">
              <tbody>
                <tr>
                  <td>
                    <span class="has-text-primary has-text-weight-bold">
                      {{ $t('Previous') }}
                    </span>
                  </td>
                  <td>
                    {{
                      $filters.date(previousHousekeeping.date, { format: $variables.dateFormat })
                    }}
                  </td>
                  <td>{{ previousHousekeeping.housekeeper }}</td>
                </tr>
                <tr>
                  <td>
                    <span class="has-text-primary has-text-weight-bold">
                      {{ $t('Next') }}
                    </span>
                  </td>
                  <td>
                    {{
                      $filters.date(nextHousekeeping.date, { format: $variables.dateFormat })
                    }}
                  </td>
                  <td>{{ nextHousekeeping.housekeeper }}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <!-- /Housekeeping schedule -->
          <div
            v-if="property.anshinPropertyId && !details.reservationId"
            class="column is-12 has-text-right mt-5"
          >
            <a-button
              type="primary"
              :disabled="isPosting"
              @click="resendAnshin"
            >
              {{ $t("Resend Anshin Reservation") }}
            </a-button>
          </div>
        </div>
        <!-- the end of right section -->
      </div>
    </div>
  </div>
</template>

<i18n>
{
  "en": {
    "del-property-desc": "Once deleted, you cannot recover this property. Please be certain.",
    "Permission": "Permission",
    "Delete reservation": "Delete reservation",
    "Resend Anshin Reservation": "Resend Anshin Reservation"
  },
  "ja": {
    "del-property-desc": "一度削除すると復旧することはできません。必ず確認してください。",
    "Permission": "許可",
    "Delete reservation": "予約を削除する",
    "Resend Anshin Reservation": "予約をあんしんへ再送する"
  }
}
</i18n>

<i18n src="@/locales/components/reservations.json"></i18n>

<script>
import { mapState } from 'vuex';
import Modal from '@/components/Modal';
import IconEdit from '@/components/icons/IconEdit';
import EditBasicInformation from './components/basic-info/EditBasicInformation';
import EditGuestInformation from './components/basic-info/EditGuestInformation';
import EditOtherServiceInformation from './components/basic-info/EditOtherServiceInformation';
import EditCharge from './components/basic-info/EditCharge';
import DelReservation from './components/DelReservation';
import calculatePax from './mixins/calculatePax';
import guestEmail from './mixins/guest-email';
import { getFullname } from '@/utils/users';
import { getResponseError } from '@/utils/util';

export default {
  name: 'BasicInfo',
  components: {
    Modal,
    IconEdit,
    DelReservation,
    EditBasicInformation,
    EditOtherServiceInformation,
    EditGuestInformation,
    EditCharge,
  },
  mixins: [calculatePax, guestEmail],
  data() {
    return {
      housekeepingSchedule: {
        previous: {},
        next: {},
      },
      latestGuestEmail: null,
      isPosting: false,
    };
  },
  computed: {
    ...mapState('reservations', ['reservation']),
    details() {
      return this.reservation.details || {};
    },
    basicInfo() {
      return this.details.basicInfo || {};
    },
    member() {
      return this.details.member || {};
    },
    basicRateInfo() {
      return this.details.basicRateInfo || {};
    },
    property() {
      return this.reservation.property || {};
    },
    reservedRoom() {
      return this.reservation.reservedRoom || {};
    },
    roomInfo() {
      return this.reservedRoom.info || {};
    },
    guest() {
      return (this.reservedRoom.guests && this.reservedRoom.guests[0]) || {};
    },
    roomTypeName() {
      const { room, roomType } = this.reservedRoom || {};

      if (roomType) {
        return roomType.name;
      }

      return room ? room.code : '-';
    },
    roomNumber() {
      const { room, roomType, roomNumber } = this.reservedRoom || {};

      if (roomType) {
        return roomNumber || '-';
      }

      return room ? room.name : '-';
    },
    previousHousekeeping() {
      const previous = this.housekeepingSchedule.previous ? this.housekeepingSchedule.previous : {};
      const { cleaningDate, user } = previous;

      return {
        date: cleaningDate || '-',
        housekeeper: user ? getFullname({ fname: user.firstName, lname: user.lastName }) : '-',
      };
    },
    nextHousekeeping() {
      const next = this.housekeepingSchedule.next ? this.housekeepingSchedule.next : {};
      const { cleaningDate, user } = next;

      return {
        date: cleaningDate || '-',
        housekeeper: user ? getFullname({ fname: user.firstName, lname: user.lastName }) : '-',
      };
    },
  },
  created() {
    this.getHousekeepingSchedule();
    this.getLatestEmailLog();
  },
  methods: {
    asyncData() {
      this.$store.dispatch('reservations/get', { id: this.$route.params.id });
    },
    async getHousekeepingSchedule() {
      try {
        const { reservedRoom } = this.reservation;
        const { propertyId, roomCode, roomNumber } = reservedRoom || {};
        const payload = {
          propertyId,
          params: { roomCode, roomNumber },
        };

        const schedule = await this.$store.dispatch('properties/housekeepingSchedule', payload);

        this.housekeepingSchedule = schedule;
      } catch (err) {
        this.$message.error(getResponseError(err) || 'Error');
      }
    },
    async getLatestEmailLog() {
      try {
        const { reservationNumber } = this.reservation;
        const latest = await this.$store.dispatch('guest-email/latestEventLog', {
          reservationNumber,
        });

        this.latestGuestEmail = latest;
      } catch (err) {
        this.$message.error(getResponseError(err) || 'Error');
      }
    },
    resendAnshin() {
      this.isPosting = true;
      this.$store.dispatch('reservations/addAnshin', { id: this.$route.params.id })
        .then(() => {
          this.isPosting = false;
          this.$message.success('Anshin reservation created');
          this.asyncData();
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.housekeeping-schedule {
  td {
    padding: 5px 15px 5px 0;
  }
}

.email-status {
  display: inline-block;
  color: #FFF;
  padding: 0 5px;
}

.email-status--sent {
  background-color: #2ac72a;
}

.email-status--failed {
  background-color: #ff3939;
}

.email-status--in-progress {
  background-color: #009ae9;
}
</style>
