<template>
  <div class="page">
    <div class="section">
      <div class="container">
        <div class="columns">
          <div class="column">
            <h1 class="title is-4 has-text-primary">
              {{ $t('Event log') }}
            </h1>
          </div>
        </div>
        <a-table
          :columns="columns"
          :data-source="logs"
          :loading="isFetching"
          :custom-row="rowClicked"
          :pagination="pagination"
          row-key="id"
        >
          <template #createdAt="{ record }">
            {{ $filters.date(record.createdAt) }}
          </template>
          <template #timeValue="{ record }">
            <span>{{ getSendTime(record) }}</span>
          </template>
          <template #status="{ record }">
            <span
              v-if="record.status === EMAIL_EVENT_STATUS.SENT"
              class="has-text-success"
            >
              Yes
            </span>
            <span
              v-else-if="record.status === EMAIL_EVENT_STATUS.IN_PROGRESS"
            >
              In progress
            </span>
            <span
              v-else
              class="has-text-danger"
            >
              No
            </span>
          </template>
          <template #details="{ record }">
            {{ record.status === EMAIL_EVENT_STATUS.FAILED ? record.details || '-' : '-' }}
          </template>
          <template #resend="{ record }">
            <a v-if="!record?.emailTemplate.deletedAt"
               href="javascript:;"
               @click="handleResend(record.id)"
            >Resend</a>
          </template>
        </a-table>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import pagination from '@/components/mixins/pagination';
import { EMAIL_EVENT_STATUS } from '@/config/guest-email';
import guestEmail from './mixins/guest-email';

export default {
  name: 'EmailLog',
  mixins: [pagination, guestEmail],
  data() {
    return {
      EMAIL_EVENT_STATUS,
      isFetching: false,
      logs: undefined,
      log: undefined,
      columns: [
        {
          title: this.$t('Timestamp'),
          dataIndex: 'createdAt',
          key: 'createdAt',
          slots: { customRender: 'createdAt' },
        },
        {
          title: this.$t('When to send'),
          dataIndex: 'timeValue',
          slots: { customRender: 'timeValue' },
        },
        {
          title: this.$t('Email Subject'),
          dataIndex: 'subject',
          key: 'subject',
          slots: { customRender: 'subject' },
        },
        {
          title: this.$t('Sent'),
          dataIndex: 'status',
          key: 'status',
          slots: { customRender: 'status' },
        },
        {
          title: this.$t('Details'),
          dataIndex: 'details',
          key: 'details',
          width: 360,
          slots: { customRender: 'details' },
        },
        {
          title: this.$t('Resend'),
          slots: { customRender: 'resend' },
        },
      ],
    };
  },
  computed: {
    ...mapState('reservations', ['reservation']),
  },
  created() {
    this.fetchData();
  },
  methods: {
    async handleTableChange(pagination) {
      await this.preparePagination(pagination);
      await this.fetchData();
    },
    async fetchData() {
      this.$store.commit('SHOW_FULLSCREEN_LOADER');

      const params = { reservationNumber: this.reservation.reservationNumber };

      return this.$store.dispatch('guest-email/listEventLog', params)
        .then((resp) => {
          const { list, pagination } = resp;
          this.logs = list;
          this.setPagination(pagination);
        })
        .finally(() => {
          this.$store.commit('HIDE_FULLSCREEN_LOADER');
        });
    },
    handleResend(id) {
      const key = 'resendEmail';
      this.$message.loading({ content: 'Sending email...', key });

      this.$store.dispatch('guest-email/sendEmail', { id })
        .then(() => {
          this.fetchData();
          this.$message.success({ content: 'Sent successfully!', key, duration: 2 });
        });
    },
  },
};
</script>
