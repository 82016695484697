<template>
  <div class="page">
    <section class="hero is-banner is-primary">
      <div class="hero-body">
        <div class="container">
          <div class="columns is-mobile">
            <div class="column">
              <h2 class="is-3 title">
                {{ $t('Reservations Calendar') }}
              </h2>
            </div>
          </div>
        </div>
      </div>
    </section>
    <div class="section">
      <div class="container">
        <div class="columns">
          <div class="column is-narrow">
            <h3 class="has-text-primary has-text-weight-bold is-size-5 m-b-3x">
              {{ $t('Filter') }}
            </h3>
            <SearchForm
              ref="searchForm"
              date-format="DD MMMM YYYY"
              :current-date="dateFrom"
              @filter="fetchData(true)"
            />
          </div>
          <div class="column">
            <div class="columns is-vcentered">
              <div class="button-group column columns is-justify-content-flex-end">
                <div class="column is-narrow pr-0">
                  <div v-permission="['add-reservation']" class="has-text-right">
                    <BlockDateAdd v-draggable :draggable="true" @close="fetchData(true)" />
                  </div>
                </div>
                <div class="column is-narrow pr-0">
                  <div v-permission="['add-reservation']" class="has-text-right">
                    <ReservationAdd @close="fetchData(true)" />
                  </div>
                </div>
              </div>
            </div>
            <CalendarTable
              :init="init"
              :is-fetching="isFetching"
              :date-from="dateFrom"
              :date-to="dateTo"
              :days="days"
              :previous-days="previousDays"
              :list="list"
              :scroll="scroll"
              @change="onDateChange"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<i18n>
{
  "en": {
    "Reservations Calendar": "Reservations Calendar",
    "Filter": "Filter",
    "Back to all rooms":"Back to all rooms"
  },
  "ja": {
    "Reservations Calendar": "Reservations Calendar",
    "Filter": "フィルター",
    "Back to all rooms":"オールルーム"
  }
}
</i18n>

<script>
import dayjs from 'dayjs';
import SearchForm from '@/views/reservations/components/calendar/SearchForm';
import CalendarTable from '@/views/reservations/components/calendar/CalendarTable';
import ReservationAdd from '@/views/reservations/components/ReservationAddModal';
import BlockDateAdd from '@/views/calendar/components/BlockDateAddModal';

export default {
  name: 'Calendar',
  components: {
    CalendarTable,
    SearchForm,
    ReservationAdd,
    BlockDateAdd,
  },
  data() {
    return {
      list: undefined,
      isFetching: false,
      days: 14,
      previousDays: 2,
      partnerList: [],
      init: false,
      scroll: {
        x: 0,
        y: 475,
      },
      horizontalScrollInc: 990,
    };
  },
  computed: {
    hasroomTypeId() {
      return !!this.$route.query.roomTypeId;
    },
    dateFrom() {
      return this.$route.query.date
        ? this.$route.query.date
        : dayjs(new Date()).format('YYYY-MM-DD');
    },
    dateTo() {
      return dayjs(this.dateFrom)
        .add(this.days - 1, 'days')
        .format('YYYY-MM-DD');
    },
  },
  methods: {
    /**
     * initial fetchData call will happen after SearchForm.vue component is created
     * called through filter emit on SearchForm.vue
     */
    fetchData() {
      const { propertyId } = this.$route.query;
      /* propertyId is required */
      if (!propertyId) {
        this.list = [];
        this.$message.error('Please select a property.');
        return;
      }
      this.init = true;
      if (this.list === undefined) {
        this.$store.commit('SHOW_FULLSCREEN_LOADER');
      }

      this.isFetching = true;

      const dateFrom = dayjs(this.dateFrom).subtract(this.previousDays, 'd').format('YYYY-MM-DD');
      const dateTo = dayjs(this.dateFrom).add(this.days - this.previousDays, 'd').format('YYYY-MM-DD');

      const payload = {
        dateFrom,
        dateTo,
        propertyId,
        roomTypeId: this.$route.query.roomTypeId ? this.$route.query.roomTypeId : undefined,
      };
      this.$store
        .dispatch('reservations/calendar', payload)
        .then((resp) => {
          this.scroll.x = this.horizontalScrollInc;
          this.list = this.transformResponse(resp);
        })
        .finally(() => {
          this.$store.commit('HIDE_FULLSCREEN_LOADER');
          this.isFetching = false;
        });
    },
    onDateChange() {
      this.fetchData();
    },
    transformResponse(response) {
      if (!response?.data) {
        return [];
      }
      return Object.values(response.data);
    },
  },
};
</script>

<style lang="scss" scoped>
.button-group {
  display: flex;
}

@media screen and (max-width: 768px) {
  h3.m-b-3x {
    margin-bottom: 5px!important;
  }
  .section {
    padding: 5px 15px 15px 15px;
  }
  .button-group {
    flex-wrap: wrap;
    margin-left: -5px;
    margin-right: -5px;
    margin-top: -25px;
    margin-bottom: -15px;
  }
  .button-group .column {
    padding: 5px!important;
  }
  .button-group .column:nth-child(1) {
    order: 3;
    flex: 0 0 100%;
    :deep .ant-btn {
      width: 100%;
    }
  }
  .button-group .column:nth-child(2), .button-group .column:nth-child(3) {
    :deep .ant-btn {
      padding-left: 5px;
      padding-right: 5px;
      letter-spacing: -0.02rem;
      width: 100%;
    }
  }
  :deep .ant-checkbox-group > div:last-child {
    margin-bottom: 0;
  }
}
</style>
