<template>
  <div class="page">
    <section class="hero is-banner is-primary">
      <div class="hero-body">
        <div class="container">
          <h2 class="has-text-centered is-3 title">
            {{ $t('Add New Reservation') }}
          </h2>
        </div>
      </div>
    </section>
    <div class="section">
      <div class="container is-mobi">
        <ReservationForm
          ref="reservationForm"
          :is-submitting="isSubmitting"
          @submit="handleSubmit"
        />
        <a-alert v-if="reservation" type="success">
          <template #message>
            <span v-html="$t('added', { number: reservation.reservationNumber })" />
            <router-link to="/reservations">
              {{ $t('view') }}
            </router-link>
            .
          </template>
        </a-alert>
      </div>
    </div>
  </div>
</template>

<i18n>
{
  "en": {
    "view": "View reservations page",
    "added": "Reservation <strong>{number}</strong> added,"
  },
  "ja": {
    "view": "予約ページを見る",
    "added": "予約 <strong>{number}</strong> を追加,"
  }
}
</i18n>

<script>
import ReservationForm from './components/ReservationForm';

export default {
  name: 'AddReservation',
  components: {
    ReservationForm,
  },
  data() {
    return {
      isSubmitting: false,
      reservation: undefined,
    };
  },
  methods: {
    handleSubmit(data) {
      this.isSubmitting = true;
      this.$store
        .dispatch('reservations/add', data)
        .then((resp) => {
          this.reservation = resp.data;
          this.$refs.reservationForm.resetFields();
        })
        .finally(() => {
          this.isSubmitting = false;
        });
    },
  },
};
</script>
