<template>
  <div class="section">
    <div v-if="reservation" class="container">
      <div class="columns is-large-gap">
        <div class="column is-6">
          <div class="columns columns-table is-gapless is-mobile is-size-7 m-b-0">
            <div class="column">
              <div class="th">
                {{ $t('Member') }}
              </div>
            </div>
            <div class="column has-text-right">
              <div class="th">
                <Modal>
                  <template #default="{ hide }">
                    <EditMember
                      :value="reservation"
                      @close="
                        () => {
                          hide();
                          asyncData();
                        }
                      "
                      @cancel="hide"
                    />
                  </template>
                  <template #handler="{ show }">
                    <a class="has-text-white" @click.prevent="show">
                      <IconBase width="13" height="13" icon-name="edit">
                        <IconEdit />
                      </IconBase>
                      {{ $t('Edit') }}</a
                    >
                  </template>
                </Modal>
              </div>
            </div>
          </div>
          <div class="columns columns-table is-gapless is-multiline is-size-7 m-b-0">
            <div class="column is-6">
              <div class="td">
                <div class="has-text-primary has-text-weight-bold">
                  {{ $t('User Name') }}
                </div>
                <div>{{ member.name }}</div>
              </div>
            </div>
            <div class="column is-6">
              <div class="td">
                <div class="has-text-primary has-text-weight-bold">
                  {{ $t('Print Category') }}
                </div>
                <div>{{ member.printCategory }}</div>
              </div>
            </div>
            <div class="column is-6">
              <div class="td">
                <div class="has-text-primary has-text-weight-bold">
                  {{ $t('User Kana') }}
                </div>
                <div>{{ member.kana }}</div>
              </div>
            </div>
            <div class="column is-6">
              <div class="td">
                <div class="has-text-primary has-text-weight-bold">
                  {{ $t('Member Status') }}
                </div>
                <div>{{ member.status }}</div>
              </div>
            </div>
            <div class="column is-6">
              <div class="td">
                <div class="has-text-primary has-text-weight-bold">
                  {{ $t('User Tel') }}
                </div>
                <div>{{ member.phone }}</div>
              </div>
            </div>
            <div class="column is-6">
              <div class="td">
                <div class="has-text-primary has-text-weight-bold">
                  {{ $t('User Givin Points') }}
                </div>
                <div>{{ member.givinPoints }}</div>
              </div>
            </div>
            <div class="column is-6">
              <div class="td">
                <div class="has-text-primary has-text-weight-bold">
                  {{ $t('User Mail Address') }}
                </div>
                <div>{{ member.email }}</div>
              </div>
            </div>
            <div class="column is-6">
              <div class="td">
                <div class="has-text-primary has-text-weight-bold">
                  {{ $t('Point Name') }}
                </div>
                <div>
                  {{
                    member.usePointList && member.usePointList.length > 0
                      ? member.usePointList[0].name
                      : ''
                  }}
                </div>
              </div>
            </div>
            <div class="column is-6">
              <div class="td">
                <div class="has-text-primary has-text-weight-bold">
                  {{ $t('User Zip') }}
                </div>
                <div>{{ member.zip }}</div>
              </div>
            </div>
            <div class="column is-6">
              <div class="td">
                <div class="has-text-primary has-text-weight-bold">
                  {{ $t('User Use Point') }}
                </div>
                <div>
                  {{
                    member.usePointList && member.usePointList.length > 0
                      ? member.usePointList[0].point
                      : ''
                  }}
                </div>
              </div>
            </div>
            <div class="column is-6">
              <div class="td">
                <div class="has-text-primary has-text-weight-bold">
                  {{ $t('User Addr') }}
                </div>
                <div>{{ member.address }}</div>
              </div>
            </div>
            <div class="column is-6">
              <div class="td">
                <div class="has-text-primary has-text-weight-bold">
                  {{ $t('Total Accommodation Declease Points') }}
                </div>
                <div>{{ member.totalAccommodationDecleasePoints }}</div>
              </div>
            </div>
            <div class="column is-6">
              <div class="td">
                <div class="has-text-primary has-text-weight-bold">
                  {{ $t('User Corp') }}
                </div>
                <div>{{ member.corporation }}</div>
              </div>
            </div>
            <div class="column is-6">
              <div class="td">
                <div class="has-text-primary has-text-weight-bold">
                  {{ $t('Amount Claimed') }}
                </div>
                <div>{{ member.amountClaimed }}</div>
              </div>
            </div>
            <div class="column is-6">
              <div class="td">
                <div class="has-text-primary has-text-weight-bold">
                  {{ $t('Member Number') }}
                </div>
                <div>{{ member.number }}</div>
              </div>
            </div>
            <div class="column is-6">
              <div class="td">
                <div class="has-text-primary has-text-weight-bold">
                  {{ $t('Representative Person Age') }}
                </div>
                <div>{{ details.representativePersonAge }}</div>
              </div>
            </div>
          </div>
        </div>

        <div class="column is-6">
          <div class="columns columns-table is-gapless is-mobile is-size-7 m-b-0">
            <div class="column">
              <div class="th">
                {{ $t('Accompanying Person List') }}
              </div>
            </div>
            <div class="column has-text-right is-narrow">
              <div class="th">
                <Modal>
                  <template #default="{ hide }">
                    <EditAccompanyingPersonList
                      :value="reservation"
                      @close="
                        () => {
                          hide();
                          asyncData();
                        }
                      "
                      @cancel="hide"
                    />
                  </template>
                  <template #handler="{ show }">
                    <a class="has-text-white" @click.prevent="show">
                      <IconBase width="13" height="13" icon-name="edit">
                        <IconEdit />
                      </IconBase>
                      {{ $t('Edit') }}</a
                    >
                  </template>
                </Modal>
              </div>
            </div>
          </div>
          <div class="columns columns-table is-gapless is-multiline is-size-7 m-b-0">
            <div class="column is-6">
              <div class="td">
                <div class="has-text-primary has-text-weight-bold">
                  {{ $t('Accompanying Person Name') }}
                </div>
                <div>{{ accompanyingPersonList.name }}</div>
              </div>
            </div>
            <div class="column is-6">
              <div class="td">
                <div class="has-text-primary has-text-weight-bold">
                  {{ $t('Accompanying Person Age') }}
                </div>
                <div>{{ accompanyingPersonList.age }}</div>
              </div>
            </div>
            <div class="column is-6">
              <div class="td">
                <div class="has-text-primary has-text-weight-bold">
                  {{ $t('Accompanying Person Kana Name') }}
                </div>
                <div>{{ accompanyingPersonList.kana }}</div>
              </div>
            </div>
            <div class="column is-6">
              <div class="td">
                <div class="has-text-primary has-text-weight-bold">
                  {{ $t('Accompanying Person Sex Category') }}
                </div>
                <div>{{ accompanyingPersonList.genderCategory }}</div>
              </div>
            </div>
          </div>

          <div class="columns columns-table is-gapless is-mobile is-size-7 m-b-0">
            <div class="column">
              <div class="th">
                {{ $t('Add Form List') }}
              </div>
            </div>
            <div class="column has-text-right">
              <div class="th">
                <Modal>
                  <template #default="{ hide }">
                    <EditAddFormList
                      :value="reservation"
                      @close="
                        () => {
                          hide();
                          asyncData();
                        }
                      "
                      @cancel="hide"
                    />
                  </template>
                  <template #handler="{ show }">
                    <a class="has-text-white" @click.prevent="show">
                      <IconBase width="13" height="13" icon-name="edit">
                        <IconEdit />
                      </IconBase>
                      {{ $t('Edit') }}</a
                    >
                  </template>
                </Modal>
              </div>
            </div>
          </div>
          <div class="columns columns-table is-gapless is-multiline is-size-7 m-b-0">
            <div class="column is-6">
              <div class="td">
                <div class="has-text-primary has-text-weight-bold">
                  {{ $t('Add Form Name') }}
                </div>
                <div>{{ addFormList.name }}</div>
              </div>
            </div>
            <div class="column is-6">
              <div class="td">
                <div class="has-text-primary has-text-weight-bold">
                  {{ $t('Add Form Value') }}
                </div>
                <div>{{ addFormList.value }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import Modal from '@/components/Modal';
import IconEdit from '@/components/icons/IconEdit';
import EditMember from './components/other-info/EditMember';
import EditAccompanyingPersonList from './components/other-info/EditAccompanyingPersonList';
import EditAddFormList from './components/other-info/EditAddFormList';

export default {
  name: 'OtherInfo',
  components: {
    IconEdit,
    Modal,
    EditMember,
    EditAddFormList,
    EditAccompanyingPersonList,
  },
  computed: {
    ...mapState('reservations', ['reservation']),
    details() {
      return this.reservation.details || {};
    },
    member() {
      return this.details.member || {};
    },
    accompanyingPersonList() {
      return this.details.accompanyingPersonList?.[0] || {};
    },
    addFormList() {
      return this.details.addFormList?.[0] || {};
    },
  },
  methods: {
    asyncData() {
      this.$store.dispatch('reservations/get', { id: this.$route.params.id });
    },
  },
};
</script>

<i18n src="@/locales/components/reservations.json"></i18n>
